import { gql } from "@apollo/client";
import {
  Intent,
  MenuItemProps,
  Placement,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import {
  DateTimeString,
  HexType,
  OrgRole,
  ProjectLanguage,
  SpecialVersionType,
  UPDATE_HEX,
  getDateTimeString,
  humanReadableOrgRole,
  isOrgRoleSuperset,
} from "@hex/common";
import { saveAs } from "file-saver";
import { Base64 } from "js-base64";
import React, { useCallback } from "react";
import { shallowEqual } from "react-redux";
import styled from "styled-components";

import {
  HexKeyCombo,
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
  HexPopover,
  HexTooltip,
} from "../../hex-components";
import { useHexAOContext } from "../../hex-multiplayer/hexAOContext.js";
import { useHexSelector } from "../../hex-version-multiplayer/state-hooks/hexStateHooks.js";
import { useHexVersionSelector } from "../../hex-version-multiplayer/state-hooks/hexVersionStateHooks";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { useDuplicateProject } from "../../hooks/useDuplicateProject.js";
import { useExportProject } from "../../hooks/useExportProject";
import { HexMP } from "../../redux/slices/hexMPSlice.js";
import { CyData } from "../../util/cypress";
import { useDialog } from "../../util/dialogs";
import { HotKeys } from "../../util/hotkeys";
import { useProjectContext } from "../../util/projectContext";
import { useEnforceProjectLimit } from "../../util/useEnforceProjectLimit";
import { ContactAnAdmin } from "../common/ContactAnAdmin";
import { useFeatureGates } from "../feature-gate/FeatureGateContext.js";
import { FeatureGateToolTip } from "../feature-gate/FeatureGateToolTip";
import {
  AddIcon,
  ArchiveIcon,
  CollectionIcon,
  DuplicateIcon,
  EditIcon,
  TransferOwnerIcon,
  TrashIcon,
  UnarchiveIcon,
  UploadIcon,
} from "../icons/CustomIcons";
import { useCreateNewDefaultProject } from "../upload/useCreateNewDefaultProject";

import { useExportIpynbMutation } from "./HexOptionsMenu.generated";
import { StarMenuItem } from "./StarMenuItem.js";

interface HexOptionsMenuProps {
  className?: string;
  children: React.ReactNode;
  isDisabled?: boolean;
  isStarred?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  canSetOwner?: boolean;
  onRenameHex: () => void;
  trashed: boolean;
  openAddCollectionDialog: () => void;
  openTransferOwnerDialog: () => void;
}

gql`
  mutation ExportIpynb($hexVersionId: HexVersionId!) {
    exportIpynb(hexVersionId: $hexVersionId) {
      filename
      content
    }
  }

  mutation ExportProject($hexId: HexId!) {
    exportHexVersion(hexId: $hexId) {
      filename
      content
    }
  }
`;

const StyledHexPopover = styled(HexPopover)`
  && {
    display: flex;
  }
`;

export function MenuItemWithTooltip(
  props: MenuItemProps & {
    tooltipContent: string | JSX.Element;
    isMenuDisabled: boolean | undefined;
    isTooltipDisabled: boolean | undefined;
    tooltipPlacement?: Placement;
  },
): React.ReactElement {
  const {
    isMenuDisabled,
    isTooltipDisabled,
    tooltipContent,
    tooltipPlacement,
    ...menuProps
  } = props;
  return (
    <HexTooltip
      content={tooltipContent}
      disabled={isTooltipDisabled}
      placement={tooltipPlacement}
      targetTagName="div"
    >
      <HexMenuItem {...menuProps} disabled={isMenuDisabled} />
    </HexTooltip>
  );
}

interface ArchiveMenuItemProps {
  archivedDate: DateTimeString | null;
  canDelete: boolean | undefined;
  toggleProjectArchivedCallback: () => void;
  unarchivedDate: DateTimeString | null;
}

export const ArchiveMenuItem: React.FunctionComponent<ArchiveMenuItemProps> = ({
  archivedDate,
  canDelete,
  toggleProjectArchivedCallback,
  unarchivedDate,
}) => {
  const { autoArchive: autoArchiveEnabled } = useFeatureGates();
  const archiveTooltip =
    unarchivedDate != null && canDelete && autoArchiveEnabled
      ? "This project was restored after it was archived. It will not be automatically archived, but can be manually archived."
      : "Only users with 'Full Access' permissions can archive projects";

  return (
    <MenuItemWithTooltip
      icon={archivedDate != null ? <UnarchiveIcon /> : <ArchiveIcon />}
      isMenuDisabled={!canDelete}
      isTooltipDisabled={!unarchivedDate && canDelete}
      text={archivedDate ? "Unarchive" : "Archive"}
      tooltipContent={archiveTooltip}
      onClick={toggleProjectArchivedCallback}
    />
  );
};

export const HexOptionsMenu: React.FunctionComponent<HexOptionsMenuProps> = ({
  canDelete,
  canEdit,
  canSetOwner,
  children,
  className,
  isDisabled,
  isStarred,
  onRenameHex,
  openAddCollectionDialog,
  openTransferOwnerDialog,
  trashed,
}) => {
  const { hexId, hexType, hexVersionId, language } = useProjectContext();
  const isComponent = hexType === HexType.COMPONENT;
  const currentUser = useCurrentUser();
  const currentUserOrgRole = currentUser?.orgRole;
  const isSupport = currentUser?.org.supportUserId === currentUser?.id;
  const exportProject = useExportProject({ hexId });
  const hexLabel = isComponent ? "component" : "project";

  const duplicateProject = useDuplicateProject({ hexId, isComponent });
  const { openDialog: openTrashDialog } = useDialog("trash-hex");

  const { dispatchAO: dispatchHexAO } = useHexAOContext();
  const { archivedDate, unarchivedDate } = useHexSelector({
    selector: (hex: HexMP) => ({
      archivedDate: hex.archivedDate,
      unarchivedDate: hex.unarchivedDate,
    }),
    equalityFn: shallowEqual,
  });

  const toggleProjectArchived = useCallback(async () => {
    await dispatchHexAO(
      UPDATE_HEX.create(
        "archivedDate",
        archivedDate != null ? null : getDateTimeString(new Date()),
      ),
    ).promise.then(() => location.reload());
  }, [archivedDate, dispatchHexAO]);

  const [exportIpynb] = useExportIpynbMutation();

  const exportIpynbCallback = useCallback(async () => {
    if (hexVersionId) {
      const { data: exportData } = await exportIpynb({
        variables: { hexVersionId },
      });
      if (exportData) {
        const { content, filename } = exportData.exportIpynb;
        saveAs(
          new Blob([Base64.decode(content)], {
            type: "application/json",
          }),
          filename,
        );
      }
      // TODO: show an error toast if exporting fails
    }
  }, [exportIpynb, hexVersionId]);

  const { createPythonProject, createRProject } = useCreateNewDefaultProject();

  const onNewProjectClick = useCallback(async () => {
    if (language === ProjectLanguage.PYTHON) {
      await createPythonProject();
    } else if (language === ProjectLanguage.R) {
      await createRProject();
    }
  }, [createPythonProject, createRProject, language]);

  const { projectLimitEnforced } = useEnforceProjectLimit();
  const currentUserOrgRoleIsSupersetOfEditor =
    currentUserOrgRole && isOrgRoleSuperset(currentUserOrgRole, OrgRole.EDITOR);

  const isDraftVersion = useHexVersionSelector({
    selector: (hexVersion) => hexVersion.version === SpecialVersionType.DRAFT,
  });

  const userCanDuplicateAndCreate =
    currentUserOrgRoleIsSupersetOfEditor && isDraftVersion;

  const duplicateTooltipContent = !currentUserOrgRoleIsSupersetOfEditor
    ? `You must be a workspace ${humanReadableOrgRole(
        OrgRole.EDITOR,
      )} in order to duplicate a ${hexLabel}`
    : !isDraftVersion
      ? "Cannot duplicate a previous version. Exit version preview to duplicate."
      : "";

  const canRename = !trashed && canEdit && isDraftVersion;
  const canTrash = !trashed && canDelete && isDraftVersion;

  const menuContents = (
    <HexMenu>
      <MenuItemWithTooltip
        icon={<EditIcon />}
        isMenuDisabled={!canRename}
        isTooltipDisabled={canRename}
        text="Rename"
        tooltipContent="Cannot rename in read-only mode"
        onClick={onRenameHex}
      />
      <StarMenuItem isStarred={isStarred} />
      {/* TODO(GRVTY-916): enforce feature gate limit for components as well */}
      <FeatureGateToolTip
        content="duplicate projects. Your workspace has reached its project limit"
        disabled={
          !userCanDuplicateAndCreate ||
          isSupport ||
          isComponent ||
          !projectLimitEnforced // only show feature gate tooltip if user would otherwise be able to duplicate
        }
        featureGate="projectLimit"
      >
        <MenuItemWithTooltip
          data-cy={CyData.DUPLICATE_PROJECT}
          icon={<DuplicateIcon />}
          isMenuDisabled={
            (!userCanDuplicateAndCreate && !isSupport) ||
            (!isComponent && projectLimitEnforced)
          }
          isTooltipDisabled={userCanDuplicateAndCreate || isSupport}
          text="Duplicate"
          tooltipContent={duplicateTooltipContent}
          onClick={duplicateProject}
        />
      </FeatureGateToolTip>
      <MenuItemWithTooltip
        data-cy={CyData.EXPORT_AS}
        icon={<UploadIcon />}
        isMenuDisabled={!isDraftVersion}
        isTooltipDisabled={isDraftVersion}
        text="Export as"
        tooltipContent="Cannot export a previous version. Exit version preview to export."
      >
        <HexMenuItem
          data-cy={CyData.EXPORT_AS_YAML}
          disabled={!hexVersionId}
          labelElement={<HexKeyCombo combo={HotKeys.EXPORT_PROJECT} />}
          text="Hex project (.yaml)"
          onClick={exportProject}
        />
        {language === ProjectLanguage.PYTHON && (
          <HexMenuItem
            disabled={!hexVersionId}
            text="Jupyter notebook (.ipynb)"
            onClick={exportIpynbCallback}
          />
        )}
      </MenuItemWithTooltip>
      <MenuItemWithTooltip
        icon={<TransferOwnerIcon />}
        isMenuDisabled={!canSetOwner}
        isTooltipDisabled={canSetOwner}
        text={`Transfer ${isComponent ? "component" : "project"} owner`}
        tooltipContent={`Only users with 'Full Access' permissions can transfer ${
          isComponent ? "component" : "project"
        } owners`}
        onClick={openTransferOwnerDialog}
      />
      <HexTooltip
        content={
          <>
            You do not have permissions to add to a collection.{" "}
            <ContactAnAdmin /> for additional permissions.
          </>
        }
        disabled={currentUserOrgRoleIsSupersetOfEditor}
        interactionKind={PopoverInteractionKind.HOVER}
      >
        <HexMenuItem
          disabled={!currentUserOrgRoleIsSupersetOfEditor}
          icon={<CollectionIcon />}
          text="Add to collection"
          onClick={openAddCollectionDialog}
        />
      </HexTooltip>
      <ArchiveMenuItem
        archivedDate={archivedDate}
        canDelete={canDelete}
        toggleProjectArchivedCallback={toggleProjectArchived}
        unarchivedDate={unarchivedDate}
      />
      <MenuItemWithTooltip
        data-cy={CyData.DELETE_HEX}
        icon={<TrashIcon />}
        intent={canTrash ? Intent.DANGER : Intent.NONE}
        isMenuDisabled={!canTrash}
        isTooltipDisabled={canTrash}
        text="Move to trash"
        tooltipContent={
          !isDraftVersion
            ? "Cannot move to trash in read-only mode"
            : "Only users with 'Full Access' permissions can move to trash"
        }
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => openTrashDialog()}
      />
      {!isComponent && (
        <>
          <HexMenuDivider />
          <FeatureGateToolTip
            content="create projects. Your workspace has reached its project limit"
            disabled={
              !currentUserOrgRoleIsSupersetOfEditor || !projectLimitEnforced // only show feature gate tooltip if user would otherwise be able to duplicate
            }
            featureGate="projectLimit"
          >
            <MenuItemWithTooltip
              data-cy={CyData.NEW_PROJECT}
              icon={<AddIcon />}
              isMenuDisabled={
                !currentUserOrgRoleIsSupersetOfEditor || projectLimitEnforced
              }
              isTooltipDisabled={currentUserOrgRoleIsSupersetOfEditor}
              labelElement={<HexKeyCombo combo={HotKeys.NEW_PROJECT} />}
              text="New project"
              tooltipContent={`You must be a workspace ${humanReadableOrgRole(
                OrgRole.EDITOR,
              )} in order to create a new project`}
              onClick={onNewProjectClick}
            />
          </FeatureGateToolTip>
        </>
      )}
    </HexMenu>
  );

  return (
    <StyledHexPopover
      className={className}
      content={menuContents}
      disabled={isDisabled}
      minimal={true}
      placement="bottom-start"
    >
      {children}
    </StyledHexPopover>
  );
};
